import React, { useState } from 'react';
import axios from 'axios';

function App() {
    const [message, setMessage] = useState('');

    // Function to make API call
    const fetchMessage = async () => {
        try {
            const response = await axios.get('https://107.21.86.153/api/message'); // Make sure to use your backend URL here
            setMessage(response.data.message);
        } catch (error) {
            console.error('Error fetching the message', error);
        }
    };

    return (
        <div className="App">
            <h1>Frontend</h1>
            <button onClick={fetchMessage}>Get Message from Backend</button>
            {message && <p>{message}</p>}
        </div>
    );
}

export default App;
